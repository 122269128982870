// -- Fonts
// Material Icons
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined');

// Roboto
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

// Muli
@import url('https://fonts.googleapis.com/css?family=Muli:300,400,800');

// Mulish
@import url('https://fonts.googleapis.com/css?family=Mulish:100,400,500,600,700,800');

// Eurostile
@import url("https://use.typekit.net/mqv7gff.css");


// Override Material Icon stylesheet with Vuetify defaults
.v-icon.v-icon--size-x-large {
  font-size: 2em;
}

.v-icon.v-icon--size-large {
  font-size: 1.75em;
}

.v-icon.v-icon--size-default {
  font-size: 1.5em;
}

.v-icon.v-icon--size-small {
  font-size: 1.25em;
}

.v-icon.v-icon--size-x-small {
  font-size: 1em;
}


