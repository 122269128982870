@import "~@/styles/injected-styles.scss";

#global-action-dialogue {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.global-action-dialogue-wrapper {
  &.v-overlay__content {
    &.dialog-container {
      background-color: transparent !important;
    }
  }

  .dialog--global-action-prompt {
    display: flex;
    flex-direction: column;
    min-width: 400px;
    width: 27rem !important;
    min-height: 5rem;
    max-height: 20rem;
    overflow: auto;

    .global-action-prompt--container {
      width: 100%;
      position: relative;
      padding: 30px;
      background-color: #fff;

      .row-fields,
      .row-buttons {
        width: 80%;
        margin: 0 auto;
      }

      .row-fields {
        h3 {
          span {
            font-size: small;
            opacity: 0.3;
          }
        }
      }

      .row-buttons {
        margin: 20px 0;
        display: flex;
        justify-content: center;
        width: 100%;

        .v-btn {
          margin: 0 10px;
        }
      }
    }
  }
}
