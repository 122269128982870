
*,
*:before,
*:after {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent; // For some Android phones
}

html {
  overflow-x: auto;
}

body,
html {
    -webkit-text-size-adjust: 100%; // Some mobile devices don't scale up text size on device rotation
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: $app-color-background;
    color: $app-color-text--dark;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    letter-spacing: 0.05em;
    font-size: 80%;
    margin: 0;
}

a,
button {
    text-decoration: none;
    outline: 0;

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
    }
}

ul,
ol {
    margin: 0;
    padding: 0;

    li {
        text-decoration: none;
        list-style-type: none;
    }
}

label {
    font-weight: 400;
}


.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #646464;
  width: 15px;
  height: 15px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.med-loader {
  border: 5px solid #cccccc;
  border-top: 5px solid #646464;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: center-spin 2s linear infinite;
  animation: center-spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: 0 0;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@-webkit-keyframes center-spin {
  0% { -webkit-transform: rotate(0deg) translate(-50%, -50%); }
  100% { -webkit-transform: rotate(360deg) translate(-50%, -50%); }
}

@keyframes center-spin {
  0% { transform: rotate(0deg) translate(-50%, -50%); }
  100% { transform: rotate(360deg) translate(-50%, -50%); }
}



