@import "~@/styles/injected-styles.scss";

  .toast-container {
    z-index: 5000 !important;

    .v-snack__wrapper {
      background: {
        color: $app-color-grey--dark-4;
      }
    }

    .v-snackbar__content {
      display: flex;

      .toast__clear-btn {
        margin: {
          left: 0.5rem;
        }
      }
    }
  }
