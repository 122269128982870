header.app-bar {
  box-shadow: none !important;
  position: relative !important;

  .v-toolbar__content {
    display: none;
  }
}

main.app-content {
  padding: 0 !important;
}

.aggrid-component.orders {
  display: none;
}

.orders__control-bar__details {
  color: #000 !important;

  .btn__container,
  .comments-btn {
    display: none;
  }
}

.orders--single-search {
  display: none;
}
