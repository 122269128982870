#global-long-wait-blocker {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  width:100%;
  height:100%;
  background-color: rgba(0, 0, 0, 0.65);

  display: none;
  text-align: center;
  align-items: center;
  &.show {
    display: flex;
  }

  .copy {
    width: 90%;
    left:5%;
    flex:0 1 auto;
    color: rgba(255, 255, 255, 0.7);
    font-size:14px;
    position: relative;
    user-select: none;
  }
}
