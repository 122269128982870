/*
/* Material Color Pallet
/*
*/

/* RED */
$material-color-red : #F44336;
$material-color-red-lighten-5 : #FFEBEE;
$material-color-red-lighten-4 : #FFCDD2;
$material-color-red-lighten-3 : #EF9A9A;
$material-color-red-lighten-2 : #E57373;
$material-color-red-lighten-1 : #EF5350;
$material-color-red-darken-1  : #E53935;
$material-color-red-darken-2  : #D32F2F;
$material-color-red-darken-3  : #C62828;
$material-color-red-darken-4  : #B71C1C;
$material-color-red-accent-1  : #FF8A80;
$material-color-red-accent-2  : #FF5252;
$material-color-red-accent-3  : #FF1744;
$material-color-red-accent-4  : #D50000;

/* PINK */
$material-color-pink : #E91E63;
$material-color-pink-lighten-5 : #FCE4EC;
$material-color-pink-lighten-4 : #F8BBD0;
$material-color-pink-lighten-3 : #F48FB1;
$material-color-pink-lighten-2 : #F06292;
$material-color-pink-lighten-1 : #EC407A;
$material-color-pink-darken-1  : #D81B60;
$material-color-pink-darken-2  : #C2185B;
$material-color-pink-darken-3  : #AD1457;
$material-color-pink-darken-4  : #880E4F;
$material-color-pink-accent-1  : #FF80AB;
$material-color-pink-accent-2  : #FF4081;
$material-color-pink-accent-3  : #F50057;
$material-color-pink-accent-4  : #C51162;

/* PURPLE */
$material-color-purple : #9C27B0;
$material-color-purple-lighten-5 : #F3E5F5;
$material-color-purple-lighten-4 : #E1BEE7;
$material-color-purple-lighten-3 : #CE93D8;
$material-color-purple-lighten-2 : #BA68C8;
$material-color-purple-lighten-1 : #AB47BC;
$material-color-purple-darken-1  : #8E24AA;
$material-color-purple-darken-2  : #7B1FA2;
$material-color-purple-darken-3  : #6A1B9A;
$material-color-purple-darken-4  : #4A148C;
$material-color-purple-accent-1  : #EA80FC;
$material-color-purple-accent-2  : #E040FB;
$material-color-purple-accent-3  : #D500F9;
$material-color-purple-accent-4  : #AA00FF;

/* DEEP PURPLE */
$material-color-deep-purple : #673AB7;
$material-color-deep-purple-lighten-5 : #EDE7F6;
$material-color-deep-purple-lighten-4 : #D1C4E9;
$material-color-deep-purple-lighten-3 : #B39DDB;
$material-color-deep-purple-lighten-2 : #9575CD;
$material-color-deep-purple-lighten-1 : #7E57C2;
$material-color-deep-purple-darken-1  : #5E35B1;
$material-color-deep-purple-darken-2  : #512DA8;
$material-color-deep-purple-darken-3  : #4527A0;
$material-color-deep-purple-darken-4  : #311B92;
$material-color-deep-purple-accent-1  : #B388FF;
$material-color-deep-purple-accent-2  : #7C4DFF;
$material-color-deep-purple-accent-3  : #651FFF;
$material-color-deep-purple-accent-4  : #6200EA;

/* INDIGO */
$material-color-indigo : #3F51B5;
$material-color-indigo-lighten-5 : #E8EAF6;
$material-color-indigo-lighten-4 : #C5CAE9;
$material-color-indigo-lighten-3 : #9FA8DA;
$material-color-indigo-lighten-2 : #7986CB;
$material-color-indigo-lighten-1 : #5C6BC0;
$material-color-indigo-darken-1  : #3949AB;
$material-color-indigo-darken-2  : #303F9F;
$material-color-indigo-darken-3  : #283593;
$material-color-indigo-darken-4  : #1A237E;
$material-color-indigo-accent-1  : #8C9EFF;
$material-color-indigo-accent-2  : #536DFE;
$material-color-indigo-accent-3  : #3D5AFE;
$material-color-indigo-accent-4  : #304FFE;

/* BLUE */
$material-color-blue : #2196F3;
$material-color-blue-lighten-5 : #E3F2FD;
$material-color-blue-lighten-4 : #BBDEFB;
$material-color-blue-lighten-3 : #90CAF9;
$material-color-blue-lighten-2 : #64B5F6;
$material-color-blue-lighten-1 : #42A5F5;
$material-color-blue-darken-1  : #1E88E5;
$material-color-blue-darken-2  : #1976D2;
$material-color-blue-darken-3  : #1565C0;
$material-color-blue-darken-4  : #0D47A1;
$material-color-blue-accent-1  : #82B1FF;
$material-color-blue-accent-2  : #448AFF;
$material-color-blue-accent-3  : #2979FF;
$material-color-blue-accent-4  : #2962FF;

/* LIGHT BLUE */
$material-color-light-blue : #03A9F4;
$material-color-light-blue-lighten-5 : #E1F5FE;
$material-color-light-blue-lighten-4 : #B3E5FC;
$material-color-light-blue-lighten-3 : #81D4FA;
$material-color-light-blue-lighten-2 : #4FC3F7;
$material-color-light-blue-lighten-1 : #29B6F6;
$material-color-light-blue-darken-1  : #039BE5;
$material-color-light-blue-darken-2  : #0288D1;
$material-color-light-blue-darken-3  : #0277BD;
$material-color-light-blue-darken-4  : #01579B;
$material-color-light-blue-accent-1  : #80D8FF;
$material-color-light-blue-accent-2  : #40C4FF;
$material-color-light-blue-accent-3  : #00B0FF;
$material-color-light-blue-accent-4  : #0091EA;

/* CYAN */
$material-color-cyan : #00BCD4;
$material-color-cyan-lighten-5 : #E0F7FA;
$material-color-cyan-lighten-4 : #B2EBF2;
$material-color-cyan-lighten-3 : #80DEEA;
$material-color-cyan-lighten-2 : #4DD0E1;
$material-color-cyan-lighten-1 : #26C6DA;
$material-color-cyan-darken-1  : #00ACC1;
$material-color-cyan-darken-2  : #0097A7;
$material-color-cyan-darken-3  : #00838F;
$material-color-cyan-darken-4  : #006064;
$material-color-cyan-accent-1  : #84FFFF;
$material-color-cyan-accent-2  : #18FFFF;
$material-color-cyan-accent-3  : #00E5FF;
$material-color-cyan-accent-4  : #00B8D4;

/* TEAL */
$material-color-teal : #009688;
$material-color-teal-lighten-5 : #E0F2F1;
$material-color-teal-lighten-4 : #B2DFDB;
$material-color-teal-lighten-3 : #80CBC4;
$material-color-teal-lighten-2 : #4DB6AC;
$material-color-teal-lighten-1 : #26A69A;
$material-color-teal-darken-1  : #00897B;
$material-color-teal-darken-2  : #00796B;
$material-color-teal-darken-3  : #00695C;
$material-color-teal-darken-4  : #004D40;
$material-color-teal-accent-1  : #A7FFEB;
$material-color-teal-accent-2  : #64FFDA;
$material-color-teal-accent-3  : #1DE9B6;
$material-color-teal-accent-4  : #00BFA5;

/* GREEN */
$material-color-green : #4CAF50;
$material-color-green-lighten-5 : #E8F5E9;
$material-color-green-lighten-4 : #C8E6C9;
$material-color-green-lighten-3 : #A5D6A7;
$material-color-green-lighten-2 : #81C784;
$material-color-green-lighten-1 : #66BB6A;
$material-color-green-darken-1  : #43A047;
$material-color-green-darken-2  : #388E3C;
$material-color-green-darken-3  : #2E7D32;
$material-color-green-darken-4  : #1B5E20;
$material-color-green-accent-1  : #B9F6CA;
$material-color-green-accent-2  : #69F0AE;
$material-color-green-accent-3  : #00E676;
$material-color-green-accent-4  : #00C853;

/* LIGHT GREEN */
$material-color-light-green : #8BC34A;
$material-color-light-green-lighten-5 : #F1F8E9;
$material-color-light-green-lighten-4 : #DCEDC8;
$material-color-light-green-lighten-3 : #C5E1A5;
$material-color-light-green-lighten-2 : #AED581;
$material-color-light-green-lighten-1 : #9CCC65;
$material-color-light-green-darken-1  : #7CB342;
$material-color-light-green-darken-2  : #689F38;
$material-color-light-green-darken-3  : #558B2F;
$material-color-light-green-darken-4  : #33691E;
$material-color-light-green-accent-1  : #CCFF90;
$material-color-light-green-accent-2  : #B2FF59;
$material-color-light-green-accent-3  : #76FF03;
$material-color-light-green-accent-4  : #64DD17;

/* LIME */
$material-color-light-green : #CDDC39;
$material-color-light-green-lighten-5 : #F9FBE7;
$material-color-light-green-lighten-4 : #F0F4C3;
$material-color-light-green-lighten-3 : #E6EE9C;
$material-color-light-green-lighten-2 : #DCE775;
$material-color-light-green-lighten-1 : #D4E157;
$material-color-light-green-darken-1  : #C0CA33;
$material-color-light-green-darken-2  : #AFB42B;
$material-color-light-green-darken-3  : #9E9D24;
$material-color-light-green-darken-4  : #827717;
$material-color-light-green-accent-1  : #F4FF81;
$material-color-light-green-accent-2  : #EEFF41;
$material-color-light-green-accent-3  : #C6FF00;
$material-color-light-green-accent-4  : #AEEA00;

/* YELLOW */
$material-color-yellow : #FFEB3B;
$material-color-yellow-lighten-5 : #FFFDE7;
$material-color-yellow-lighten-4 : #FFF9C4;
$material-color-yellow-lighten-3 : #FFF59D;
$material-color-yellow-lighten-2 : #FFF176;
$material-color-yellow-lighten-1 : #FFEE58;
$material-color-yellow-darken-1  : #FDD835;
$material-color-yellow-darken-2  : #FBC02D;
$material-color-yellow-darken-3  : #F9A825;
$material-color-yellow-darken-4  : #F57F17;
$material-color-yellow-accent-1  : #FFFF8D;
$material-color-yellow-accent-2  : #FFFF00;
$material-color-yellow-accent-3  : #FFEA00;
$material-color-yellow-accent-4  : #FFD600;

/* YELLOW */
$material-color-amber : #FFC107;
$material-color-amber-lighten-5 : #FFF8E1;
$material-color-amber-lighten-4 : #FFECB3;
$material-color-amber-lighten-3 : #FFE082;
$material-color-amber-lighten-2 : #FFD54F;
$material-color-amber-lighten-1 : #FFCA28;
$material-color-amber-darken-1  : #FFB300;
$material-color-amber-darken-2  : #FFA000;
$material-color-amber-darken-3  : #FF8F00;
$material-color-amber-darken-4  : #FF6F00;
$material-color-amber-accent-1  : #FFE57F;
$material-color-amber-accent-2  : #FFD740;
$material-color-amber-accent-3  : #FFC400;
$material-color-amber-accent-4  : #FFAB00;

/* ORANGE */
$material-color-orange : #FF9800;
$material-color-orange-lighten-5 : #FFF3E0;
$material-color-orange-lighten-4 : #FFE0B2;
$material-color-orange-lighten-3 : #FFCC80;
$material-color-orange-lighten-2 : #FFB74D;
$material-color-orange-lighten-1 : #FFA726;
$material-color-orange-darken-1  : #FB8C00;
$material-color-orange-darken-2  : #F57C00;
$material-color-orange-darken-3  : #EF6C00;
$material-color-orange-darken-4  : #E65100;
$material-color-orange-accent-1  : #FFD180;
$material-color-orange-accent-2  : #FFAB40;
$material-color-orange-accent-3  : #FF9100;
$material-color-orange-accent-4  : #FF6D00;

/* DEEP ORANGE */
$material-color-deep-orange : #FF5722;
$material-color-deep-orange-lighten-5 : #FBE9E7;
$material-color-deep-orange-lighten-4 : #FFCCBC;
$material-color-deep-orange-lighten-3 : #FFAB91;
$material-color-deep-orange-lighten-2 : #FF8A65;
$material-color-deep-orange-lighten-1 : #FF7043;
$material-color-deep-orange-darken-1  : #F4511E;
$material-color-deep-orange-darken-2  : #E64A19;
$material-color-deep-orange-darken-3  : #D84315;
$material-color-deep-orange-darken-4  : #BF360C;
$material-color-deep-orange-accent-1  : #FF9E80;
$material-color-deep-orange-accent-2  : #FF6E40;
$material-color-deep-orange-accent-3  : #FF3D00;
$material-color-deep-orange-accent-4  : #DD2C00;

/* BROWN */
$material-color-brown : #795548;
$material-color-brown-lighten-5 : #EFEBE9;
$material-color-brown-lighten-4 : #D7CCC8;
$material-color-brown-lighten-3 : #BCAAA4;
$material-color-brown-lighten-2 : #A1887F;
$material-color-brown-lighten-1 : #8D6E63;
$material-color-brown-darken-1  : #6D4C41;
$material-color-brown-darken-2  : #5D4037;
$material-color-brown-darken-3  : #4E342E;
$material-color-brown-darken-4  : #3E2723;

/* BLUE GREY */
$material-color-blue-grey : #607D8B;
$material-color-blue-grey-lighten-5 : #ECEFF1;
$material-color-blue-grey-lighten-4 : #CFD8DC;
$material-color-blue-grey-lighten-3 : #B0BEC5;
$material-color-blue-grey-lighten-2 : #90A4AE;
$material-color-blue-grey-lighten-1 : #78909C;
$material-color-blue-grey-darken-1  : #546E7A;
$material-color-blue-grey-darken-2  : #455A64;
$material-color-blue-grey-darken-3  : #37474F;
$material-color-blue-grey-darken-4  : #263238;

/* GREY */
$material-color-grey : #9E9E9E;
$material-color-grey-lighten-5 : #FAFAFA;
$material-color-grey-lighten-4 : #F5F5F5;
$material-color-grey-lighten-3 : #EEEEEE;
$material-color-grey-lighten-2 : #E0E0E0;
$material-color-grey-lighten-1 : #BDBDBD;
$material-color-grey-darken-1  : #757575;
$material-color-grey-darken-2  : #616161;
$material-color-grey-darken-3  : #424242;
$material-color-grey-darken-4  : #212121;

/* SHADES */
$material-color-black : #000000;
$material-color-white : #FFFFFF;
$material-color-transparent : rgba(0, 0, 0, 0);
