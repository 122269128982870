@import "~@/styles/injected-styles.scss";

.its-date-picker {
  position: relative;

  .date-picker-container {
    position: absolute;
    top: 1rem;
    z-index: 500;
    height: 0;
    overflow: hidden;

    &.open {
      height: 600px;
    }

    .date-picker {
      border: 1px solid $app-color-grey--light-2;
    }
  }
}

